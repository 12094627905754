@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

body {
    margin: 0;
    font-family: 'Plus Jakarta Sans' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .theme-font {
    font-family: 'Plus Jakarta Sans' !important;
  }

  @layer components {
    .custom-input .MuiInputBase-formControl, .custom-input input:focus, .custom-input textarea:focus .MuiInputBase-formControl {
      background-color: white;
    }
    .custom-input .MuiFilledInput-underline:after, .custom-input .MuiFilledInput-underline:before  {
      @apply !border-none;
    }
  }

  .MuiInputBase-input:after {
    border: none !important;
  }

  /* Custom CSS class to remove the bottom border */
.no-bottom-border .MuiInput-underline:before {
  border-bottom: none;
}

.wrapper {
  display: inline-block;
  height: 20px;
  padding:10px;
  width:0%;
}

/* .contents {
  display:inline-block;
  width:0%;
  white-space:nowrap;
  overflow:hidden;
} */

.wrapper:hover {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  width:100%;
}


@keyframes textAppear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Apply the animation to the text element */
.text-with-gradient {
  animation: textAppear 2s linear forwards;
}

